"use client";

import { use } from "react";
import Link from "next/link";
import { useUser } from "@/context/user-context";
import { ChevronRight } from "lucide-react";

import { cn } from "@/lib/utils";
import { buttonVariants } from "@/components/ui/button";
import { CommandMenu } from "@/components/command-menu";
import { MainNav } from "@/components/main-nav";
import { MobileNav } from "@/components/mobile-nav";
import { ModeToggle } from "@/components/mode-toggle";
import { UserAccountNav } from "@/components/user-account-nav";

export function SiteHeader() {
  const { userPromise } = useUser();
  const user = use(userPromise);
  return (
    <header
      className={cn(
        "supports-backdrop-blur:bg-background/90 sticky top-0 z-40 w-full  bg-background/40 backdrop-blur-lg",
      )}
    >
      <div className="container flex h-14 items-center">
        <MainNav />
        <MobileNav />
        <div className="flex flex-1 items-center justify-between gap-2 md:justify-end">
          <div className="w-full flex-1 md:w-auto md:flex-none">
            <CommandMenu />
          </div>
          <nav className="flex items-center gap-2">
            <ModeToggle />
            {user && (
              <UserAccountNav
                user={{
                  name: user.name,
                  image: user.image,
                  email: user.email,
                }}
              />
            )}
            {!user && (
              <>
                <Link
                  href="/login"
                  className={cn(
                    buttonVariants({ variant: "secondary", size: "sm" }),
                    "px-4 hidden md:flex",
                  )}
                >
                  Login
                </Link>
                <Link
                  className={cn(
                    buttonVariants({
                      variant: "rainbow",
                      size: "sm",
                    }),
                  )}
                  href={"/#pricing"}
                >
                  <div className="inline md:hidden">Get Access</div>
                  <div className="hidden md:inline">Get Unlimited Access</div>
                  <ChevronRight className="h-4 w-4" />
                </Link>
              </>
            )}
          </nav>
        </div>
      </div>
      <hr className="m-0 h-px w-full border-none bg-gradient-to-r from-border/0 via-border/70 to-border/0" />
    </header>
  );
}
